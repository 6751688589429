.playerNameContainer {
    color: black;
    font-weight: bolder;
    font-size: 32px;
    background-color: white;
    border-top: 3px solid red;
    margin-top: 1%;
}

.customButton {
    background-color: #bd5e61;
    color: white;
    border: 3px solid #3b95d4;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight:bolder;
}

.customButton:hover {
    background-color: white;
    color: #bd5e61;
}

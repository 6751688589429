
  .red-text {
    color: red;
  }
  
  .pointer-cursor {
    cursor: pointer;
  }
  

.player-card-img {
    transition: background-color 0.3s ease; 
    cursor: pointer;
}
  
.player-card-img:hover {
background-color: #EDF2FB !important;
}

.player-card-body:hover{
  background-color: #EDF2FB !important;
}
  
  